import { Box, Transition, Text } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { useState, useEffect, useRef, MutableRefObject } from "react";
import { Trans, useTranslation } from "next-i18next";
import { Carousel } from "@mantine/carousel";
import Autoplay, { AutoplayType } from "embla-carousel-autoplay";
import Link from "next/link";

const CarouselSlide = ({ index }: { index: number }) => {
  const { t } = useTranslation();

  return (
    <Carousel.Slide>
      <Text className="px-6 font-bold !text-white text-xs md:text-sm">
        <Trans
          i18nKey={`top:slider_message.${index}`}
          components={{
            a: <Link href={`tel:${t("common:service.contact")}`} />,
          }}
        />
      </Text>
    </Carousel.Slide>
  );
};

const NoticeCarousel = ({
  styles,
  autoplay,
}: {
  styles?: any;
  autoplay: MutableRefObject<AutoplayType>;
}) => {
  const { t } = useTranslation();
  const sliderTexts = t("top:slider_message", {
    returnObjects: true,
  }) as string[];

  return (
    <Carousel
      style={styles}
      plugins={[autoplay.current]}
      controlsOffset={0}
      slideGap={0}
      draggable={false}
      align="start"
      classNames={{
        slide:
          "min-h-10 md:min-h-8 flex justify-center  items-center gap-2 py-2 bg-green-600",
        control:
          "bg-transparent border-none shadow-none text-white data-[inactive]:opacity-0",
      }}
    >
      {sliderTexts ? (
        sliderTexts.map((_, index) => (
          <CarouselSlide key={index} index={index} />
        ))
      ) : (
        <CarouselSlide index={0} />
      )}
    </Carousel>
  );
};

export function CustomNotice(): JSX.Element {
  const { t } = useTranslation();
  const [displayNotice, setDisplayNotice] = useState<boolean>(false);
  const { start } = useTimeout(() => setDisplayNotice(true), 1000);
  const autoplayPc = useRef(Autoplay({ delay: 10000 }));
  const autoplaySp = useRef(Autoplay({ delay: 10000 }));

  /**
   * Automatically hide notice after a certain time.
   * Customize this custom notice according to needed notice display.
   *
   * If slider has deadline, use the formula below and update noticeDeadline value.
   * isNoticeDeadline = dayjs(new Date()).isAfter(dayjs(noticeDeadline))
   */
  // const noticeDeadline = "2024/08/19";
  const isNoticeDeadline = false;

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isNoticeDeadline && (
        <>
          <Box visibleFrom="md" className="absolute top-17 z-[99] w-full">
            <Transition
              mounted={displayNotice}
              transition="slide-down"
              duration={500}
              timingFunction="ease"
            >
              {(styles) => (
                <NoticeCarousel styles={styles} autoplay={autoplayPc} />
              )}
            </Transition>
          </Box>
          <Box hiddenFrom="md" className="absolute top-17 z-[99] w-full">
            <NoticeCarousel autoplay={autoplaySp} />
          </Box>
        </>
      )}
    </>
  );
}
