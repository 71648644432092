import { Box, List, Text } from "@mantine/core";

export function GuideCaution({
  heading,
  list,
}: {
  heading?: string;
  list: any;
}): JSX.Element {
  return (
    <Box
      bg="blue.1"
      className="rounded-md text-dark-8"
      py="xs"
      px="md"
      my="lg"
    >
      {heading && (
        <Text size="sm" c="red.9">
          {heading}
        </Text>
      )}
      <List c="red.9" fz="sm">
        {list.map((item: any, index: number) => (
          <List.Item key={index}>{item}</List.Item>
        ))}
      </List>
    </Box>
  );
}
