import { Popover, Text } from "@mantine/core";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

export function PopoverAlert({
  children,
  opened,
  text,
}: {
  children: React.ReactNode;
  opened: boolean;
  text?: string;
}): JSX.Element {
  return (
    <Popover opened={opened} position="bottom" offset={2} withArrow>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown className="flex gap-2 text-inherit px-3 !z-[100]">
        <Text c="yellow.6" px={0} pt={0}>
          <IconAlertTriangleFilled />
        </Text>
        <Text className="normal-case" size="md" px={0} pt={0}>
          {text}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
}
