import { createTheme, rem } from "@mantine/core";

export const searchTheme = createTheme({
  components: {
    Autocomplete: {
      defaultProps: {
        size: "md",
        classNames: {
          input: "bg-transparent border-0",
        },
      },
    },
    DatePickerInput: {
      defaultProps: {
        size: "md",
      },
    },
    InputBase: {
      defaultProps: {
        size: "md",
        classNames: {
          input: "bg-transparent border-0",
        },
      },
    },
    Text: {
      defaultProps: {
        size: "xs",
        fw: 500,
        pt: rem(10),
        px: rem(15),
        className: "uppercase text-dark-8",
      },
    },
  },
});
