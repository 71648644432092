import { Box, Flex, Stack, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { GuideCaution } from "../Guide/Caution";

export function TicketAgeClassification(): JSX.Element {
  const { t, ready } = useTranslation();

  const types = t("common:search.tickets", { returnObjects: true }) as any;
  const caution = t("common:search.caution.contents", {
    returnObjects: true,
  }) as any;

  return (
    <>
      <Stack gap={0} className="border border-solid border-dark-5 border-t-0">
        {ready &&
          types?.map((type: any, index: number) => (
            <Flex key={index} direction={{ base: "column", md: "row" }}>
              <Box
                bg="dark.4"
                className={`border border-solid border-dark-5 border-l-0 border-r-0 
                          lg:border-b-0`}
                w={{
                  base: "100%",
                  md: "25%",
                }}
              >
                <Text ta="center" py="xs" className="text-sm lg:text-base">
                  {type.class}
                </Text>
              </Box>
              <Box
                w={{ base: "100%", md: "75%" }}
                className={`lg:border lg:border-solid lg:border-dark-5 lg:border-l-1
                          lg:border-r-0 lg:border-b-0`}
              >
                <Text p="xs" className="text-sm lg:text-base">
                  {type.description}
                </Text>
              </Box>
            </Flex>
          ))}
      </Stack>

      {ready && (
        <GuideCaution
          heading={`${t("common:search.caution.title")}:`}
          list={caution}
        />
      )}
    </>
  );
}
