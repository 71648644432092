import {
  Combobox,
  Stack,
  TextInput,
  useCombobox,
  Text,
  Badge,
  rem,
  Loader,
  TextInputProps,
  Modal,
  Flex,
  Button,
  Center,
  Divider,
} from "@mantine/core";
import {
  FormEvent,
  MouseEventHandler,
  RefObject,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "next-i18next";
import { useDisclosure } from "@mantine/hooks";
import { useDeviceQuery } from "@/utils";
import { searchStations } from "@/api";
import { IJrStation, IRoute, IStation, IStationListResponse } from "@/types";
import { STATIONS } from "@/constants/stations";
import { IconSearch } from "@tabler/icons-react";

interface Props extends TextInputProps {
  placeholder: string;
  inputRef?: RefObject<HTMLInputElement>;
  name?: string;
  label?: string;
  routeData?: IRoute;
  setRouteData: (route: any) => void;
  cardRef: React.RefObject<HTMLDivElement> | null;
  data: string;
  setValues: (values: any) => void;
  popularRoutes?: IRoute[];
}

export function LocationField({
  placeholder,
  inputRef,
  name,
  label,
  routeData,
  setRouteData,
  cardRef,
  data,
  setValues,
  popularRoutes,
  ...rest
}: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const [locationType, setLocationType] = useState<string>();
  const [isMobile] = useDeviceQuery();
  const [stations, setStations] = useState<IStationListResponse>();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const selectedStations: string[] = [
    "TOKYO",
    "KYOTO",
    "SHIN_OSAKA",
    "NAGOYA",
    "HIROSHIMA",
    "SHINAGAWA",
    "KANAZAWA",
    "SENDAI",
    "ODAWARA",
    "NARITA_T1",
    "KARUIZAWA",
    "MATSUMOTO",
    "TSURUGA",
    "SHIN_YOKOHAMA",
    "ATAMI",
    "UENO",
    "OMIYA",
    "SHIN_KOBE",
    "HAKATA",
    "KANSAI_AIRPORT",
    "SHINJUKU",
    "SAPPORO",
    "SHIN_HAKODATE",
    "NAGANO",
    "HAKUBA",
    "IIYAMA",
    "TOYAMA",
    "TAKAYAMA",
    "ECHIGO_YUZAWA",
    "JOETSU_MYOKO",
    "KORIYAMA",
  ];
  const popularStations = selectedStations.map((name) => STATIONS[name]);

  /**
   * Handle selection of routes or station.
   */
  const handleSelectLocation = (route: any) => {
    close();
    if (route?.from && route?.to) {
      const departure = t(
        `locations:prefectures.${route.from?.prefecture}.${route.from?.name}`
      );
      const destination = t(
        `locations:prefectures.${route.to?.prefecture}.${route.to?.name}`
      );
      setValues({
        depStationName: departure,
        arvStationName: destination,
      });
      setRouteData({
        from: {
          code: route.from.code,
          name: departure,
        },
        to: {
          code: route.to.code,
          name: destination,
        },
      });
    } else if (locationType === "depStationName") {
      setValues((value: any) => ({
        ...value,
        depStationName: route.name,
      }));
      setRouteData((prevData: any) => ({ ...prevData, from: route }));
    } else if (locationType === "arvStationName") {
      setValues((value: any) => ({ ...value, arvStationName: route.name }));
      setRouteData((prevData: any) => ({ ...prevData, to: route }));
    }
  };

  const handleInputClick = (e: FormEvent<HTMLInputElement>) => {
    if (isMobile) {
      open();
    }
    const input = e.target as HTMLInputElement;
    combobox.toggleDropdown();
    setLocationType(input.name);
  };

  /**
   * If input data is empty, display suggested dropdown.
   */
  useEffect(() => {
    if (data !== "") {
      searchStations({ locale: i18n.language, word: data })
        .then((res) => {
          setLoading(false);
          setStations(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data, i18n.language]);

  const PopularBadge = () => {
    return (
      <Badge
        variant="filled"
        color="orange.9"
        radius="sm"
        fw={500}
        fz="xs"
        className="normal-case"
        px={rem(5)}
      >
        {t("common:popular")}
      </Badge>
    );
  };

  /**
   * If user doesn't select anything from dropdown but inputted data has
   * a match, select matched data.
   */
  const checkInputMatch = () => {
    let suggestedMatch: IJrStation | undefined = undefined;
    if (stations?.jrStationList && stations?.jrStationList?.length > 1) {
      suggestedMatch = stations?.jrStationList?.find(
        (station) => station.name?.toLowerCase() === data?.toLowerCase()
      );
    } else {
      suggestedMatch = stations?.jrStationList && stations?.jrStationList[0];
    }

    if (data !== "" && suggestedMatch && routeData === undefined) {
      handleSelectLocation({
        code: suggestedMatch?.nodeId,
        name: suggestedMatch?.name,
      });
    }
  };

  const PopularRoutes = ({ route }: { route: IRoute }) => {
    return (
      <Flex justify="space-between" align="center" className="w-full">
        <Text
          size="sm"
          className="normal-case flex-1"
          px={0}
          pt={0}
          fw={400}
          ta="left"
        >
          {t(
            `locations:prefectures.${route?.from?.prefecture}.${route?.from?.name}`
          )}{" "}
          -{" "}
          {t(
            `locations:prefectures.${route?.to?.prefecture}.${route?.to?.name}`
          )}
        </Text>
        <PopularBadge />
      </Flex>
    );
  };

  const PopularStations = ({ station }: { station: IStation }) => {
    return (
      <Flex justify="space-between" align="center" className="w-full">
        <Stack gap={0} align="flex-start">
          <Text size="sm" className="normal-case" px={0} pt={0} fw={400}>
            {t(`locations:prefectures.${station?.prefecture}.${station?.name}`)}
          </Text>
          <Text
            size="xs"
            className="normal-case"
            px={0}
            pt={0}
            fw={400}
            c="dark.6"
          >
            {t(
              `locations:prefectures.${station?.prefecture}.${station?.prefecture}`
            )}
          </Text>
        </Stack>
        <PopularBadge />
      </Flex>
    );
  };

  const SearchOptions = ({ station }: { station: IJrStation }) => {
    return (
      <Flex justify="space-between" align="center" className="w-full">
        <Stack gap={0} align="flex-start" className="flex-1">
          <Text size="sm" className="normal-case" px={0} pt={0} fw={400}>
            {station.name}
          </Text>
          {station.distance > 0 && (
            <Text
              size="xs"
              className="normal-case"
              px={0}
              pt={0}
              fw={400}
              c="dark.6"
            >
              {t("common:distance_from_station", {
                distance: Math.floor(station.distance / 1000),
                station: station.defaultStation,
              })}
            </Text>
          )}
        </Stack>
        <Badge
          fw={500}
          fs={rem(11)}
          radius={rem(4)}
          px={rem(5)}
          color={station.shinkansen ? "blue.9" : "dark.6"}
        >
          {t(`common:train_type.${station.shinkansen ? 0 : 1}`)}
        </Badge>
      </Flex>
    );
  };

  const SearchOptionButton = ({
    children,
    onClick,
  }: {
    children: React.ReactNode;
    onClick: MouseEventHandler;
  }) => {
    return (
      <Button
        variant="transparent"
        onClick={onClick}
        fullWidth
        h={rem(47)}
        classNames={{
          root: `px-0 border-b border-t-0 border-l-0 border-r-0 border-solid border-dark-2 
                rounded-none`,
          label: "w-full",
        }}
      >
        {children}
      </Button>
    );
  };

  return (
    <>
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={() => {
          combobox.closeDropdown();
        }}
        classNames={{
          dropdown: "max-h-[410px] overflow-scroll top-0",
          groupLabel: "after:hidden text-sm font-bold my-1 text-dark-8",
          option: `border-b border-t-0 border-l-0 border-r-0 border-solid border-dark-2 py-3`,
        }}
        middlewares={{ flip: false, shift: false, inline: false }}
      >
        <Combobox.Target>
          <TextInput
            ref={inputRef}
            type="text"
            name={name}
            autoComplete="off"
            placeholder={placeholder}
            classNames={{
              input: `shinkansen-text-area-vague bg-transparent border-0
                      focus:border-b-2 focus:border-orange-9 -mt-3 md:mt-0`,
            }}
            onClick={(e) => handleInputClick(e)}
            onBlur={() => checkInputMatch()}
            readOnly={isMobile}
            {...rest}
          />
        </Combobox.Target>
        <Combobox.Dropdown visibleFrom="md">
          <Combobox.Options>
            {data === "" ? (
              <>
                <Combobox.Group label={t("common:popular_routes")}>
                  {locationType === "depStationName" &&
                    popularRoutes?.map((route: IRoute, index: number) => (
                      <Combobox.Option
                        key={index}
                        value={route.from?.name || ""}
                        onClick={() => handleSelectLocation(route)}
                      >
                        <PopularRoutes route={route} />
                      </Combobox.Option>
                    ))}
                </Combobox.Group>
                <Combobox.Group label={t("common:popular_stations")}>
                  {popularStations?.map((station, index) => (
                    <Combobox.Option
                      key={index}
                      value={station.name}
                      onClick={() =>
                        handleSelectLocation({
                          code: station.code,
                          name: t(
                            `locations:prefectures.${station.prefecture}.${station.name}`
                          ),
                        })
                      }
                    >
                      <PopularStations station={station} />
                    </Combobox.Option>
                  ))}
                </Combobox.Group>
              </>
            ) : (
              <>
                {isLoading ? (
                  <Combobox.Empty>
                    <Loader color="blue.9" size="sm" />
                  </Combobox.Empty>
                ) : (
                  <>
                    {stations?.jrStationList ? (
                      stations?.jrStationList?.map((station, index) => (
                        <Combobox.Option
                          key={index}
                          value={station.name}
                          onClick={() =>
                            handleSelectLocation({
                              code: station.nodeId,
                              name: station.name,
                            })
                          }
                        >
                          <SearchOptions station={station} />
                        </Combobox.Option>
                      ))
                    ) : (
                      <Combobox.Empty>{stations?.errorMessage}</Combobox.Empty>
                    )}
                  </>
                )}
              </>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      <Modal
        opened={opened}
        onClose={close}
        fullScreen
        title={
          <>
            <Text className="mb-3 normal-case text-base !pt-0">{label}</Text>
            <Divider />
            <TextInput
              type="text"
              autoComplete="off"
              placeholder={placeholder}
              classNames={{
                root: "px-3 pt-4",
                input: `shinkansen-text-area-vague bg-dark-4 border-0
                    focus:border-b-2 focus:border-orange-9`,
              }}
              onBlur={() => checkInputMatch()}
              leftSection={<IconSearch height={18} width={18} />}
              {...rest}
            />
          </>
        }
        transitionProps={{ transition: "fade", duration: 200 }}
        hiddenFrom="md"
        classNames={{
          close: "absolute right-3 top-3",
          header: "flex flex-col w-full px-0 text-center",
          title: "w-full",
        }}
      >
        {data === "" ? (
          <>
            <Stack gap={0}>
              {locationType === "depStationName" && (
                <>
                  <Text fw={700} className="normal-case !px-0">
                    {t("common:popular_routes")}
                  </Text>
                  {popularRoutes?.map((route, index) => (
                    <SearchOptionButton
                      key={index}
                      onClick={() => handleSelectLocation(route)}
                    >
                      <PopularRoutes route={route} />
                    </SearchOptionButton>
                  ))}
                </>
              )}
            </Stack>
            <Stack gap={0} mt="md">
              <Text fw={700} className="normal-case !px-0">
                {t("common:popular_stations")}
              </Text>
              {popularStations?.map((station, index) => (
                <SearchOptionButton
                  key={index}
                  onClick={() =>
                    handleSelectLocation({
                      code: station.code,
                      name: t(
                        `locations:prefectures.${station.prefecture}.${station.name}`
                      ),
                    })
                  }
                >
                  <PopularStations station={station} />
                </SearchOptionButton>
              ))}
            </Stack>
          </>
        ) : (
          <>
            {isLoading ? (
              <Center>
                <Loader color="blue.9" size="sm" />
              </Center>
            ) : (
              <>
                {stations?.jrStationList ? (
                  stations?.jrStationList?.map((station, index) => (
                    <SearchOptionButton
                      key={index}
                      onClick={() =>
                        handleSelectLocation({
                          code: station.nodeId,
                          name: station.name,
                        })
                      }
                    >
                      <SearchOptions station={station} />
                    </SearchOptionButton>
                  ))
                ) : (
                  <Text>{stations?.errorMessage}</Text>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
